import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFinancingState, LenderWithInterestRates } from '@/types';

const initialState: IFinancingState = {
    downPayment: 50,
    tenor: 60,
    idFrontURL: '',
    idBackURL: '',
    docsURLS: [],
    application: undefined,
    approvedTerms: false,
    carLenders: [],
};

export const financingReducer = createSlice({
    name: 'financing',
    initialState,
    reducers: {
        setDownpayment: (state, action: PayloadAction<number>) => {
            state.downPayment = action.payload;
        },
        setTenor: (state, action: PayloadAction<number>) => {
            state.tenor = action.payload;
        },
        setDocsURLs: (state, action: PayloadAction<string[]>) => {
            state.docsURLS = action.payload;
        },
        addDocsURLs: (state, action: PayloadAction<string[]>) => {
            state.docsURLS = [...state.docsURLS, ...action.payload];
        },
        setApplication: (state, action: PayloadAction<any>) => {
            state.application = action.payload;
        },
        setApprovedTerms: (state, action: PayloadAction<boolean>) => {
            state.approvedTerms = action.payload;
        },
        setIdFrontUrl: (state, action: PayloadAction<string>) => {
            state.idFrontURL = action.payload;
        },
        setIdBackUrl: (state, action: PayloadAction<string>) => {
            state.idBackURL = action.payload;
        },
        setCarLenders: (
            state,
            action: PayloadAction<LenderWithInterestRates[]>
        ) => {
            state.carLenders = action.payload;
        },
    },
});

export const {
    setDownpayment,
    setTenor,
    setDocsURLs,
    addDocsURLs,
    setApplication,
    setApprovedTerms,
    setIdFrontUrl,
    setIdBackUrl,
    setCarLenders,
} = financingReducer.actions;

export default financingReducer.reducer;
