'use client';
import Image from 'next/image';

import Link from '@/components/WrappedLink';
import { usePathname, useSearchParams } from 'next/navigation';
import { ArIcon, EnIcon } from '../../../../public/NavBar';
import styles from './styles.module.scss';
import { useTranslation } from '@/hook';
import { AVAILABLE_LANGUAGES } from '@/constants';
import { poppins, tajawal } from '@/utils/font.loader';

const ChangeLanguage = ({ lang }: { lang: AVAILABLE_LANGUAGES }) => {
    const path = usePathname();
    const query = useSearchParams();
    const { SwitchLanguage } = useTranslation();

    const ar = () => {
        const queryParts = [];
        query.forEach((v: string, k: string) => {
            queryParts.push(`${k}=${v}`);
        });
        const newPath = path.replace('en', 'ar');
        return `${newPath}?${queryParts.join('&')}`;
    };
    const en = () => {
        const queryParts = [];
        query.forEach((v: string, k: string) => {
            queryParts.push(`${k}=${v}`);
        });
        const newPath = path.replace('ar', 'en');
        return `${newPath}?${queryParts.join('&')}`;
    };
    return (
        <div
            className={styles.change_language_layout}
            onClick={e => SwitchLanguage(e, lang, en(), ar())}
        >
            <Image
                src={lang === 'ar' ? EnIcon : ArIcon}
                alt="Language icon"
                className={`${styles.icon} ${styles.change_language_layout_img}`}
                dir={lang === 'ar' ? 'rtl' : 'ltr'}
            />
            <Link
                className={styles.change_language_layout_text}
                style={{ alignSelf: 'flex-end' }}
                href={lang === 'ar' ? en() : ar()}
                dir={lang === 'ar' ? 'rtl' : 'ltr'}
            >
                <p
                    style={{
                        fontFamily:
                            lang === 'en'
                                ? tajawal.style.fontFamily
                                : poppins.style.fontFamily,
                        fontSize: '1rem',
                        lineHeight: '1.5rem',
                    }}
                >
                    {lang === 'ar' ? 'English' : 'عربي'}
                </p>
            </Link>
        </div>
    );
};
export default ChangeLanguage;
