import { createApi } from '@reduxjs/toolkit/query/react';
import {
    IGetIVehicleDataForLogsResponse,
    IPaymentsLogType,
    IVehicleDataForLogsResponse,
    PaginatedData,
    PaginatedDataObject,
} from '@/types';
import { NEST_RETAIL_BACKEND } from '@/constants';
import { IPaymentsLogsResponse } from '@/types';
import { baseQueryWrapper } from '@/libs/baseQueryWrapper.lib';

export const userBookingsDetailsApiSlice = createApi({
    reducerPath: 'bookingDetails',
    baseQuery: baseQueryWrapper({ baseUrl: `${NEST_RETAIL_BACKEND}` }),
    tagTypes: ['BookingDetails'],
    keepUnusedDataFor: 0,
    endpoints: builder => ({
        getPaymentLogs: builder.query<PaginatedData<IPaymentsLogType>, any>({
            query: bookingId => ({
                url: `/v1.0/payment-documents/${bookingId}?sortBy=createdAt&sortOrder=DESC&limit=100`,
                method: 'GET',
            }),
            transformResponse: (
                response: IPaymentsLogsResponse
            ): PaginatedData<IPaymentsLogType> => {
                const data = response.payload;

                return data;
            },
        }),
        getBookingsDetails: builder.query<
            PaginatedDataObject<IVehicleDataForLogsResponse>,
            any
        >({
            query: bookingId => ({
                url: `/v1.0/booking/${bookingId}`,
                method: 'GET',
            }),
            transformResponse: (
                response: IGetIVehicleDataForLogsResponse
            ): PaginatedDataObject<IVehicleDataForLogsResponse> => {
                const data = response.payload;

                return data;
            },
        }),
    }),
});

export const { useGetPaymentLogsQuery, useGetBookingsDetailsQuery } =
    userBookingsDetailsApiSlice;
