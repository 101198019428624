'use client';
import Link from '@/components/WrappedLink';
import Image from 'next/image';

import { useEffect, useState } from 'react';
import { websiteLogoAr, websiteLogoEn } from '../../../../../public/Images';
import { Cross, HamburgerIcon } from '../../../../../public/NavBar';

import styles from './styles.module.scss';

import Divider from '@mui/material/Divider';
import { usePathname } from 'next/navigation';

import { SwipeableDrawer } from '@mui/material';

export default function NavDrawer({
    lang,
    children,
}: {
    lang: 'ar' | 'en';
    children: React.ReactNode;
}) {
    const path = usePathname();
    const [showMenu, setShowMenu] = useState(false);
    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    useEffect(() => {
        setShowMenu(false);
    }, [path]);

    return (
        <>
            <div className={styles.hamburgerMenu} onClick={() => toggleMenu()}>
                <Image src={HamburgerIcon} alt="Hamburger Icon" />
            </div>

            <SwipeableDrawer
                anchor="left"
                PaperProps={{
                    style: {
                        left: lang === 'en' ? 0 : 'unset',
                        right: lang === 'en' ? 'unset' : 0,
                    },
                }}
                open={showMenu}
                onClose={toggleMenu}
                onOpen={toggleMenu}
            >
                <div className={styles.container}>
                    <div className={styles.actionContainer}>
                        <Link style={{ height: '29px' }} href="/">
                            <Image
                                width={160}
                                height={29}
                                src={
                                    lang === 'en'
                                        ? websiteLogoEn
                                        : websiteLogoAr
                                }
                                className={styles.logo}
                                alt="Sylndr's Logo"
                            />
                        </Link>

                        <Image
                            onClick={() => toggleMenu()}
                            src={Cross}
                            alt="Close"
                        />
                    </div>
                    <Divider />
                    <>{children}</>
                </div>
            </SwipeableDrawer>
        </>
    );
}
