import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import i18n from '@/translations/client-setup';
import { IGenericResponse, IVerifyOtpBody, IVerifyOtpResponse } from '@/types';
import { SYLNDR_OTP_SERVICE_BASE_URL } from '@/constants';

export const otpApiSlice = createApi({
    reducerPath: 'otp',
    baseQuery: fetchBaseQuery({
        baseUrl: SYLNDR_OTP_SERVICE_BASE_URL,
        prepareHeaders: headers => {
            headers.set('lang', `${i18n.language}`);
            headers.set('origin', 'sylndr.com');
            return headers;
        },
    }),
    tagTypes: [],
    endpoints: builder => ({
        sendOtp: builder.mutation<IGenericResponse, string>({
            query: phone => ({
                url: '/v1.0/otp/sms/send',
                method: 'POST',
                body: { phone, language: i18n.language },
            }),
        }),
        verifyOtp: builder.mutation<IVerifyOtpResponse, IVerifyOtpBody>({
            query: ({ phone, otp }) => ({
                url: '/v1.0/otp/sms/verify',
                method: 'POST',
                body: { phone, otp },
            }),
        }),
    }),
});

export const { useSendOtpMutation, useVerifyOtpMutation } = otpApiSlice;
