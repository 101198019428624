import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BookingTypes, IFinanceApplicationData, ReserveTypes } from '@/types';

const initialState: IFinanceApplicationData = {
    id: '',
    short_id: '',
    downpayment: -1,
    months: -1,
    lender_name: '',
    address: { ar: '', en: '' },
    booking_id: '',
    booking_date: '',
    booking_type: BookingTypes.SEE,
    booking_name: '',
    name: { en: '', ar: '' },
    make: '',
    model: '',
    year: -1,
    kilometers: '',
    phone: '',
    from: '',
    to: '',
    reserve_type: ReserveTypes.SHOWROOM,
    transmission: '',
    sylndr_selling_price: '',
    images: [], // this is the car images
    discount_price: -1,
    installment_price: '',
    sf_vehicle_name: '',
    vehicleId: '',
    registration_fees: 0,
    carLenders: [],
};

export const financingResultReducer = createSlice({
    name: 'financing_result',
    initialState,
    reducers: {
        setFinanceApplicationState: (
            state,
            action: PayloadAction<IFinanceApplicationData>
        ) => {
            state.id = action.payload.id;
            state.short_id = action.payload.short_id;
            state.downpayment = action.payload.downpayment;
            state.months = action.payload.months;
            state.address = action.payload.address;
            state.booking_id = action.payload.booking_id;
            state.booking_date = action.payload.booking_date;
            state.booking_type = action.payload.booking_type;
            state.booking_name = action.payload.booking_name;
            state.name = action.payload.name;
            state.make = action.payload.make;
            state.model = action.payload.model;
            state.year = action.payload.year;
            state.kilometers = action.payload.kilometers;
            state.phone = action.payload.phone;
            state.from = action.payload.from;
            state.to = action.payload.to;
            state.reserve_type = action.payload.reserve_type;
            state.transmission = action.payload.transmission;
            state.sylndr_selling_price = action.payload.sylndr_selling_price;
            state.images = action.payload.images;
            state.discount_price = action.payload.discount_price;
            state.installment_price = action.payload.installment_price;
            state.sf_vehicle_name = action.payload.sf_vehicle_name;
            state.vehicleId = action.payload.vehicleId;
            state.registration_fees = action.payload.registration_fees;
            state.carLenders = action.payload.carLenders;
        },
    },
});

export const { setFinanceApplicationState } = financingResultReducer.actions;

export default financingResultReducer.reducer;
