import classes from './styles.module.scss';
import Link from '@/components/WrappedLink';

export enum EButtonVariant {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    INVERTED = 'inverted',
    DISABLED = 'disabled',
}
const LinkButton = ({
    variant,
    href,
    key,
    children,
    style,
}: {
    variant: EButtonVariant;
    href: string;
    key?: string;
    children: React.ReactNode;
    style?: any;
}) => {
    return (
        <Link style={style} className={classes[variant]} href={href} key={key}>
            <>{children}</>
        </Link>
    );
};
export default LinkButton;
