import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    ActiveMake,
    createProspectiveCustomer,
    IStepper_CreateAlert,
    MakeAndModelAllFlagReducer,
    MakeAndModelReducer,
} from '@/types';

interface CreateAlertFormState {
    step: number;
    open: boolean;
    disableContinueButton: boolean;
    disableSubmitButton: boolean;
    showPersonalInfoForm: boolean;
    steps: IStepper_CreateAlert;
    selectedModels: MakeAndModelReducer[];
    applySelectedModels: MakeAndModelReducer[];
    allSelectedModelsFlag: MakeAndModelAllFlagReducer[];
    allAppliedModelsFlag: MakeAndModelAllFlagReducer[];
    activeMake?: ActiveMake;
    formData: object | (createProspectiveCustomer & { id: string });
    clearSelectedBrackets: boolean;
    downPaymentError: boolean;
}
const handleAddingAndRemovingModels = (
    selectedModels: MakeAndModelReducer[],
    makeValue: string,
    modelValue: string,
    icon: any,
    label: string,
    makeLabel: string
) => {
    const currentIndex = selectedModels.findIndex(
        checkedItem =>
            checkedItem.modelValue === modelValue &&
            checkedItem.makeValue === makeValue
    );

    const newCheckedItems = [...selectedModels];
    if (currentIndex === -1) {
        // Using unshift instead of push because of UX requirment is to make the last item appear as the first option
        newCheckedItems.unshift({
            modelValue,
            makeValue,
            icon,
            label,
            makeLabel,
        });
    } else {
        newCheckedItems.splice(currentIndex, 1);
    }
    return newCheckedItems;
};

const initialState: CreateAlertFormState = {
    step: 0,
    open: false,
    applySelectedModels: [],
    steps: {
        carDetails: {
            label: 'carDetails',
            subtitle: 'carDetailsSubtitle',
            step: 0,
        },
        personalInformation: {
            label: 'personalInformation',
            subtitle: 'personalInformationSubtitle',
            step: 1,
        },
    },
    allSelectedModelsFlag: [],
    allAppliedModelsFlag: [],
    selectedModels: [],
    formData: {},
    activeMake: undefined,
    disableContinueButton: true,
    disableSubmitButton: true,
    showPersonalInfoForm: true,
    clearSelectedBrackets: false,
    downPaymentError: false,
};

const createAlertFormSlice = createSlice({
    name: 'createAlertForm',
    initialState,
    reducers: {
        setStep(state, action: PayloadAction<number>) {
            state.step = action.payload;
            state.open = false;
        },
        setOpen(state, action: PayloadAction<boolean>) {
            state.open = action.payload;
        },
        setShowPersonalInfoForm(state, action: PayloadAction<boolean>) {
            state.showPersonalInfoForm = action.payload;
        },
        setDisableContinueButton(state, action: PayloadAction<boolean>) {
            state.disableContinueButton = action.payload;
        },
        setDisableSubmitButton(state, action: PayloadAction<boolean>) {
            state.disableSubmitButton = action.payload;
        },
        setFormData(
            state,
            action: PayloadAction<createProspectiveCustomer & { id: string }>
        ) {
            state.formData = action.payload;
        },
        clearFormData(state) {
            state.formData = {};
        },
        setDownPaymentError(state, action: PayloadAction<boolean>) {
            state.downPaymentError = action.payload;
        },
        // storing active make value because watch('make') will change dynamically according to different triggers and we can not depend on it on showing the right models for multi-selections
        setActiveMake(state, action: PayloadAction<ActiveMake | undefined>) {
            state.activeMake = action.payload;
        },
        handleSelectedModels(
            state,
            action: PayloadAction<MakeAndModelReducer>
        ) {
            state.selectedModels = handleAddingAndRemovingModels(
                state.selectedModels,
                action.payload.makeValue,
                action.payload.modelValue,
                action.payload.icon,
                action.payload.label,
                action.payload.makeLabel
            );
        },
        setApplySelectedModels(
            state,
            action: PayloadAction<{
                modelValue?: string;
                makeValue?: string;
                icon?: any;
                label?: string;
                makeLabel?: string;
            }>
        ) {
            if (!action.payload?.makeValue || !action.payload?.modelValue) {
                // to store the selected models final values in the applied model state when user clicks apply button
                state.applySelectedModels = state.selectedModels;
                return;
            }

            state.applySelectedModels = handleAddingAndRemovingModels(
                state.applySelectedModels,
                action.payload.makeValue,
                action.payload.modelValue,
                action.payload.icon,
                action.payload.label,
                action.payload.makeLabel
            );
            state.allAppliedModelsFlag = state.allAppliedModelsFlag.filter(
                flag => flag.makeValue !== action.payload.makeValue
            );
            state.allSelectedModelsFlag = state.allSelectedModelsFlag.filter(
                flag => {
                    return flag.makeValue !== action.payload.makeValue;
                }
            );
        },
        removeAllModelOfOneType(
            state,
            action: PayloadAction<{
                makeValue: string;
                kind?: 'apply' | 'select';
            }>
        ) {
            if (action.payload.kind === 'select') {
                state.selectedModels = state.selectedModels.filter(
                    model => model.makeValue !== action.payload.makeValue
                );
            } else if (action.payload.kind === 'apply') {
                state.applySelectedModels = state.applySelectedModels.filter(
                    model => model.makeValue !== action.payload.makeValue
                );
            }
        },
        setSelectedToApplierModels(state) {
            state.selectedModels = state.applySelectedModels;
            state.allSelectedModelsFlag = state.allAppliedModelsFlag;
        },
        setHandleAllSelectedModelsFlag(
            state,
            action: PayloadAction<{
                type: 'add' | 'remove';
                icon?: any;
                kind?: 'apply' | 'select';
                makeLabel?: string;
                label?: string;
                makeValue: string;
            }>
        ) {
            if (action.payload.type === 'add') {
                state.allSelectedModelsFlag = [
                    ...state.allSelectedModelsFlag,
                    {
                        makeValue: action.payload.makeValue,
                        icon: action.payload.icon,
                        makeLabel: action.payload.makeLabel,
                        modelLabel: action.payload.label,
                    },
                ];
            } else if (action.payload.type === 'remove') {
                if (action.payload.kind === 'select') {
                    state.allSelectedModelsFlag =
                        state.allSelectedModelsFlag.filter(modelFlag => {
                            return (
                                modelFlag.makeValue !== action.payload.makeValue
                            );
                        });
                } else if (action.payload.kind === 'apply') {
                    state.allAppliedModelsFlag =
                        state.allAppliedModelsFlag.filter(modelFlag => {
                            return (
                                modelFlag.makeValue !== action.payload.makeValue
                            );
                        });
                }
            } else return;
        },
        setHandleAllAppliedModelsFlag(state) {
            state.allAppliedModelsFlag = state.allSelectedModelsFlag;
        },
        toggleClearSelectedBrakcets(state, action: PayloadAction<boolean>) {
            state.clearSelectedBrackets = action.payload;
        },
        resetAllFields(state) {
            state.step = 0;
            state.open = false;
            state.disableContinueButton = true;
            state.disableSubmitButton = true;
            state.selectedModels = [];
            state.applySelectedModels = [];
            state.allSelectedModelsFlag = [];
            state.allAppliedModelsFlag = [];
            state.activeMake = undefined;
            state.formData = {};
            state.clearSelectedBrackets = true;
        },
    },
});

export const {
    setStep,
    setOpen,
    setFormData,
    clearFormData,
    handleSelectedModels,
    setApplySelectedModels,
    setSelectedToApplierModels,
    setHandleAllSelectedModelsFlag,
    removeAllModelOfOneType,
    setHandleAllAppliedModelsFlag,
    setActiveMake,
    setDisableContinueButton,
    setDisableSubmitButton,
    resetAllFields,
    setShowPersonalInfoForm,
    toggleClearSelectedBrakcets,
    setDownPaymentError,
} = createAlertFormSlice.actions;
export default createAlertFormSlice.reducer;
