'use client';
import { createTheme } from '@mui/material';
// import { useTranslation } from '@/hook';
import { useTranslation } from '@/hook';
import { AVAILABLE_LANGUAGES } from '@/constants';
// import { useTranslation } from '@/translations';

const DEFAULT_FONTS = {
  [AVAILABLE_LANGUAGES.ar]: '__Tajawal_853a1a',
  [AVAILABLE_LANGUAGES.en]: '__Poppins_4ee149',
};
export const useGetTheme = () => {
  // const [fontFamily, setFontFamily] = useState(DEFAULT_FONTS[DEFAULT_LANGUAGE]);

  const { i18n, IS_RTL: isRtl } = useTranslation();

  // useEffect(() => {
  // setFontFamily(DEFAULT_FONTS[isRtl ? AVAILABLE_LANGUAGES.ar : AVAILABLE_LANGUAGES.en]);
  // }, [isRtl]);

  const regularFontWeight = 400;
  const mediumFontWeight = 500;
  const semiBoldFontWeight = 600;
  const boldFontWeight = 700;
  /**
   * All default value can be found here: https://mui.com/material-ui/customization/default-theme/
   * We can override any value here in this file
   */
  const theme: any = createTheme({
    breakpoints: {
      keys: ['xs', 'sm', 'md', 'lg', 'xl'],
      values: { xs: 0, sm: 600, md: 900, lg: 1200, xl: 1536 },
      unit: 'px',
    },

    direction: isRtl ? 'rtl' : 'ltr',
    components: {},
    palette: {
      mode: 'light',
      common: { black: '#000', white: '#fff' },
      primary: {
        main: '#009B5D',
        light: '#6ECE80',
        dark: '#006D28',
        contrastText: '#FFFFFF',
      },
      secondary: {
        main: '#1A273B',
        light: '#434F65',
        dark: '#000016',
        contrastText: '#FFFFFF',
      },
      error: {
        main: '#D32F2F',
        light: '#EF5350',
        dark: '#C62828',
        contrastText: '#D32F2F',
      },
      warning: {
        main: '#ed6c02',
        light: '#ff9800',
        dark: '#e65100',
        contrastText: '#fff',
      },
      info: {
        main: '#0288d1',
        light: '#03a9f4',
        dark: '#01579b',
        contrastText: '#fff',
      },
      success: {
        main: '#2e7d32',
        light: '#4caf50',
        dark: '#1b5e20',
        contrastText: '#fff',
      },
      grey: {
        '50': '#fafafa',
        '100': '#f5f5f5',
        '200': '#eeeeee',
        '300': '#e0e0e0',
        '400': '#bdbdbd',
        '500': '#9e9e9e',
        '600': '#757575',
        '700': '#616161',
        '800': '#424242',
        '900': '#212121',
        A100: '#f5f5f5',
        A200: '#eeeeee',
        A400: '#bdbdbd',
        A700: '#616161',
      },
      contrastThreshold: 3,
      tonalOffset: 0.2,
      text: {
        primary: 'rgba(0, 0, 0, 0.87)',
        secondary: 'rgba(0, 0, 0, 0.6)',
        disabled: 'rgba(0, 0, 0, 0.38)',
      },
      divider: 'rgba(0, 0, 0, 0.12)',
      background: { paper: '#FFFFFF', default: '#FFFFFF' },
      action: {
        active: 'rgba(0, 0, 0, 0.54)',
        hover: 'rgba(0, 0, 0, 0.04)',
        hoverOpacity: 0.04,
        selected: 'rgba(0, 0, 0, 0.08)',
        selectedOpacity: 0.08,
        disabled: 'rgba(0, 0, 0, 0.26)',
        disabledBackground: 'rgba(0, 0, 0, 0.12)',
        disabledOpacity: 0.38,
        focus: 'rgba(0, 0, 0, 0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.12,
      },
    },
    shape: { borderRadius: 4 },
    mixins: {
      toolbar: {
        minHeight: 56,
        '@media (min-width:0px)': {
          '@media (orientation: landscape)': { minHeight: 48 },
        },
        '@media (min-width:600px)': { minHeight: 64 },
      },
    },
    shadows: [
      'none',
      '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
      '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
      '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
      '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
      '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
      '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
      '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
      '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
      '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
      '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
      '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
      '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
      '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
      '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
      '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
      '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
      '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
      '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
      '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
      '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
      '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
      '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
      '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
      '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
    ],
    typography: {
      fontFamily: 'inherit',
      fontWeightLight: 400,
      fontWeightRegular: 500,
      fontWeightMedium: 600,
      fontWeightBold: 700,
      h1: {
        fontSize: '4.5rem',
        lineHeight: '5.625rem',
        letterSpacing: '-2%',
        fontWeight: regularFontWeight,
      },

      h2: {
        fontSize: '3.75rem',
        lineHeight: '4.5rem',
        letterSpacing: '-2%',
        fontWeight: regularFontWeight,
      },
      h3: {
        fontSize: '3rem',
        lineHeight: '3.75rem',
        letterSpacing: '-2%',
        fontWeight: regularFontWeight,
      },
      h4: {
        fontSize: '2.25rem',
        lineHeight: '2.75rem',
        fontWeight: regularFontWeight,
      },
      h5: {
        fontSize: '1.875rem',
        lineHeight: '2.375rem',
        fontWeight: regularFontWeight,
      },
      h6: {
        fontSize: '1.5rem',
        lineHeight: '2rem',
        fontWeight: regularFontWeight,
      },
      subtitle1: {
        fontSize: '1.25rem',
        lineHeight: '1.875rem',
        fontWeight: regularFontWeight,
      },
      subtitle2: {
        fontSize: '1.125rem',
        lineHeight: '1.75rem',
        fontWeight: regularFontWeight,
      },
      body1: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
        fontWeight: regularFontWeight,
      },
      body2: {
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        fontWeight: regularFontWeight,
      },
      button: undefined,
      caption: {
        fontSize: '0.75rem',
        lineHeight: '1.125rem',
        fontWeight: regularFontWeight,
      },
      overline: {
        fontSize: '0.9rem',
        textTransform: 'uppercase',
        lineHeight: '1.125rem',
        fontWeight: regularFontWeight,
      },
    },
    transitions: {
      easing: {
        easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
        easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
        easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
        sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
      },
      duration: {
        shortest: 150,
        shorter: 200,
        short: 250,
        standard: 300,
        complex: 375,
        enteringScreen: 225,
        leavingScreen: 195,
      },
    },
    zIndex: {
      mobileStepper: 1000,
      fab: 1050,
      speedDial: 1050,
      appBar: 1100,
      drawer: 1200,
      modal: 1300,
      snackbar: 1400,
      tooltip: 1500,
    },
  });

  theme.typography.h1Medium = {
    ...theme.typography.h1,
    fontWeight: mediumFontWeight,
  };
  theme.typography.h2Medium = {
    ...theme.typography.h2,
    fontWeight: mediumFontWeight,
  };
  theme.typography.h3Medium = {
    ...theme.typography.h3,
    fontWeight: mediumFontWeight,
  };
  theme.typography.h4Medium = {
    ...theme.typography.h4,
    fontWeight: mediumFontWeight,
  };
  theme.typography.h5Medium = {
    ...theme.typography.h5,
    fontWeight: mediumFontWeight,
  };
  theme.typography.h6Medium = {
    ...theme.typography.h6,
    fontWeight: mediumFontWeight,
  };
  theme.typography.subtitle1Medium = {
    ...theme.typography.subtitle1,
    fontWeight: mediumFontWeight,
  };
  theme.typography.subtitle2Medium = {
    ...theme.typography.subtitle2,
    fontWeight: mediumFontWeight,
  };
  theme.typography.body1Medium = {
    ...theme.typography.body1,
    fontWeight: mediumFontWeight,
  };
  theme.typography.body2Medium = {
    ...theme.typography.body2,
    fontWeight: mediumFontWeight,
  };
  theme.typography.captionMedium = {
    ...theme.typography.caption,
    fontWeight: mediumFontWeight,
  };
  theme.typography.overlineMedium = {
    ...theme.typography.overline,
    fontWeight: mediumFontWeight,
  };

  theme.typography.h1SemiBold = {
    ...theme.typography.h1,
    fontWeight: semiBoldFontWeight,
  };
  theme.typography.h2SemiBold = {
    ...theme.typography.h2,
    fontWeight: semiBoldFontWeight,
  };
  theme.typography.h3SemiBold = {
    ...theme.typography.h3,
    fontWeight: semiBoldFontWeight,
  };
  theme.typography.h4SemiBold = {
    ...theme.typography.h4,
    fontWeight: semiBoldFontWeight,
  };
  theme.typography.h5SemiBold = {
    ...theme.typography.h5,
    fontWeight: semiBoldFontWeight,
  };
  theme.typography.h6SemiBold = {
    ...theme.typography.h6,
    fontWeight: semiBoldFontWeight,
  };
  theme.typography.subtitle1SemiBold = {
    ...theme.typography.subtitle1,
    fontWeight: semiBoldFontWeight,
  };
  theme.typography.subtitle2SemiBold = {
    ...theme.typography.subtitle2,
    fontWeight: semiBoldFontWeight,
  };
  theme.typography.body1SemiBold = {
    ...theme.typography.body1,
    fontWeight: semiBoldFontWeight,
  };
  theme.typography.body2SemiBold = {
    ...theme.typography.body2,
    fontWeight: semiBoldFontWeight,
  };
  theme.typography.captionSemiBold = {
    ...theme.typography.caption,
    fontWeight: semiBoldFontWeight,
  };
  theme.typography.overlineSemiBold = {
    ...theme.typography.overline,
    fontWeight: semiBoldFontWeight,
  };

  theme.typography.h1Bold = {
    ...theme.typography.h1,
    fontWeight: boldFontWeight,
  };
  theme.typography.h2Bold = {
    ...theme.typography.h2,
    fontWeight: boldFontWeight,
  };
  theme.typography.h3Bold = {
    ...theme.typography.h3,
    fontWeight: boldFontWeight,
  };
  theme.typography.h4Bold = {
    ...theme.typography.h4,
    fontWeight: boldFontWeight,
  };
  theme.typography.h5Bold = {
    ...theme.typography.h5,
    fontWeight: boldFontWeight,
  };
  theme.typography.h6Bold = {
    ...theme.typography.h6,
    fontWeight: boldFontWeight,
  };
  theme.typography.subtitle1Bold = {
    ...theme.typography.subtitle1,
    fontWeight: boldFontWeight,
  };
  theme.typography.subtitle2Bold = {
    ...theme.typography.subtitle2,
    fontWeight: boldFontWeight,
  };
  theme.typography.body1Bold = {
    ...theme.typography.body1,
    fontWeight: boldFontWeight,
  };
  theme.typography.body2Bold = {
    ...theme.typography.body2,
    fontWeight: boldFontWeight,
  };
  theme.typography.captionBold = {
    ...theme.typography.caption,
    fontWeight: boldFontWeight,
  };
  theme.typography.overlineBold = {
    ...theme.typography.overline,
    fontWeight: boldFontWeight,
  };

  return theme;
};
