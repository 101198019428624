export { default as AboutUsIcon } from './aboutUsIcon.svg';
export { default as ArIcon } from './arIcon.svg';
export { default as BuyCarIcon } from './buyCarIcon.svg';
export { default as Cross } from './cross.svg';
export { default as EnIcon } from './enIcon.svg';
export { default as FinanceLogo } from './FinanceLogo.svg';
export { default as FAQsIcon } from './FAQsIcon.svg';
export { default as HamburgerIcon } from './HamburgerIcon.svg';
export { default as SellCarIcon } from './sellCarIcon.svg';
export { default as SylndrLogo } from './SylndrLogo.svg';
