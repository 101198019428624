export const shouldHideFooter = (isDesktop: boolean, path = ''): boolean => {
    if (isDesktop) return false;

    return (
        path.includes('checkout') ||
        path.includes('car-details') ||
        path.includes('not-found') ||
        path.includes('financing') ||
        path.includes('sell-form') ||
        path.includes('create-alert') ||
        path.includes('create-alert/success') ||
        path.includes('register') ||
        path.includes('profile') ||
        path.includes('mybookings/[id]') ||
        (path.match('account/mybookings/') && true)
    );
};
