import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    GalleryImage,
    GalleryState,
    ImperfectionsCategory,
    ImperfectionsImage,
    ModalTypes,
} from '@/types/redux-state.types';
import { RETAIL_BACKEND } from '@/constants';
import { vehicle3DFramesResponse } from '@/types';

const initialState: GalleryState = {
    galleryImages: [],
    imperfectionsImages: [],
    images3d: {
        pending: true,
        data: [],
        error: null,
    },
    imperfections3d: [],
    innerModalFlag: false,
    innerModalType: ModalTypes.Gallery,
    activeGalleryCategory: 0,
    activeImperfectionsCategory: 0,
    activeMainGalleryImage: 0,
    activeImperfectionsImage: 0,
    galleryImagesCategories: [],
    imperfectionsImagesCategories: [],
};

export const get3dImperfections = createAsyncThunk(
    'gallery/getImperfections',
    async (vehicleId: string) => {
        const response = await fetch(
            `${RETAIL_BACKEND}/vehicle-3d-frames/${vehicleId}`
        );

        return (await response.json()) as { data: vehicle3DFramesResponse[] };
    }
);

export const galleryReducer = createSlice({
    name: 'gallery',
    initialState,
    reducers: {
        setGalleryImages: (state, action: PayloadAction<GalleryImage[]>) => {
            state.galleryImages = action.payload || [];
        },
        setImperfectionsImages: (
            state,
            action: PayloadAction<ImperfectionsImage[]>
        ) => {
            state.imperfectionsImages = action.payload;
        },
        setInnerModalFlag: (state, action: PayloadAction<boolean>) => {
            state.innerModalFlag = action.payload;
            if (action.payload === false) {
                state.innerModalType = ModalTypes.Gallery;
                state.activeGalleryCategory = 0;
                state.activeImperfectionsCategory = 0;
                state.activeImperfectionsImage = 0;
            }
        },
        setInnerModalType: (state, action: PayloadAction<ModalTypes>) => {
            state.innerModalType = action.payload;
        },
        setActiveGalleryCategory: (state, action: PayloadAction<number>) => {
            state.activeGalleryCategory = action.payload;
        },
        setActiveImperfectionsCategory: (
            state,
            action: PayloadAction<number>
        ) => {
            state.activeImperfectionsCategory = action.payload;
        },
        setActiveMainGalleryImage: (state, action: PayloadAction<number>) => {
            state.activeMainGalleryImage = action.payload;
        },
        setGalleryImagesCategories: (
            state,
            action: PayloadAction<string[]>
        ) => {
            state.galleryImagesCategories = action.payload;
        },
        setImperfectionsImagesCategories: (
            state,
            action: PayloadAction<ImperfectionsCategory[]>
        ) => {
            state.imperfectionsImagesCategories = action.payload;
        },
        setActiveImperfectionsImage: (state, action: PayloadAction<number>) => {
            state.activeImperfectionsImage = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(get3dImperfections.fulfilled, (state, { payload }) => {
            state.imperfections3d = payload.data;
        });
    },
});

export const {
    setGalleryImages,
    setImperfectionsImages,
    setInnerModalFlag,
    setInnerModalType,
    setActiveGalleryCategory,
    setActiveImperfectionsCategory,
    setActiveMainGalleryImage,
    setGalleryImagesCategories,
    setImperfectionsImagesCategories,
    setActiveImperfectionsImage,
} = galleryReducer.actions;

export default galleryReducer.reducer;
