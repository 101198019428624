'use client';
import Link from '@/components/WrappedLink';
import Typography from '../typography/typography.component';
import { useAnalytics } from '@/integrations';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { usePathname } from 'next/navigation';
import { ACTION_CLICK_BUY_CAR } from '@/constants/actions';
import { possibleBuyUseCarsSources } from '@/types/filter.types';

export default function LinkItemClient({
    path,
    label,
}: {
    path: string;
    label: string;
}) {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const pathName = usePathname();
    return pathName !== path.replace(/#.*/g, '') ? (
        <Link
            className={styles.navLink}
            href={path}
            onClick={() => {
                if (label === 'Finance') {
                    trackEvent('Screen: Financing Page', {
                        sourceScreen: 'Header',
                    });
                } else if (label === 'Buy') {
                    trackEvent(ACTION_CLICK_BUY_CAR, {
                        source: possibleBuyUseCarsSources.desktop_header,
                    });
                }
            }}
        >
            <Typography variant={'body1Medium'}>{t(label)}</Typography>
        </Link>
    ) : (
        <Typography className={`${styles.navLink} ${styles.disabled}`}>
            <Typography component={'span'} variant={'body1Medium'}>
                {t(label)}
            </Typography>
        </Typography>
    );
}
