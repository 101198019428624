'use client';
import styles from './styles.module.scss';
import { LinesGreen, ProfileIconGrey } from '../../../../public/Images';
import Image from 'next/image';
// import ContentSectionComponent from './content-section.component';
import { useTranslation } from '@/hook';
import useAuth from '@/hook/use-auth.hook';
import useOnClickOutside from '@/hook/use-click-outside.hook';
import { useEffect, useRef } from 'react';
import { useRouter } from 'next/navigation';
import { AuthStepsEnum } from '@/types';
import Typography from '@/components/shared/typography/typography.component';
import colors from '@/theme/colors.module.scss';
import LoggedInSection from './content/logged-in.component';
import LoggedOutSection from './content/logged-out.component';
export default function HeaderButtonUserLogin({ lang }: { lang: any }) {
    const {
        checkForTokenAndDecode,
        showProfileCard,
        setShowProfileCardWrapper,
        updateCurrentAuthStep,
        userInitials,
        isLoggedIn,
    } = useAuth();
    const { t } = useTranslation();
    const router = useRouter();

    const containerRef = useRef(null);
    const closeProfileSection = () => {
        if (showProfileCard) {
            setShowProfileCardWrapper(false);
        }
    };
    const tagClicked = () => {
        setShowProfileCardWrapper(!showProfileCard);
    };
    const loginClicked = () => {
        updateCurrentAuthStep(AuthStepsEnum.phone);
        router.push(`/${lang}/register`);
    };

    useEffect(() => {
        checkForTokenAndDecode();
    }, []);

    useOnClickOutside(containerRef, closeProfileSection);

    return (
        <div>
            <div style={{ display: 'none' }} onClick={() => loginClicked()}>
                {t('login')}
            </div>
            {isLoggedIn ? (
                <div style={{ position: 'relative' }} ref={containerRef}>
                    <button
                        className={styles.loggedBtn}
                        onClick={() => tagClicked()}
                    >
                        <div className={styles.loggedBtn__avatar}>
                            <span>{userInitials}</span>
                        </div>
                        <Image
                            src={LinesGreen}
                            alt="LinesGreen"
                            width="20"
                            height="20"
                        />
                    </button>
                    {showProfileCard && <LoggedInSection />}
                </div>
            ) : (
                <div style={{ position: 'relative' }} ref={containerRef}>
                    <button
                        className={styles.signInBtn}
                        onClick={() => tagClicked()}
                    >
                        <div className={styles.avatarContainer}>
                            <Image
                                src={ProfileIconGrey}
                                alt="ProfileIconGrey"
                                width="12"
                                height="12"
                            />
                        </div>

                        <Typography
                            style={{ whiteSpace: 'nowrap' }}
                            variant="body2Medium"
                            color={colors.primary700}
                        >
                            {t('signIn')}
                        </Typography>

                        <Image
                            src={LinesGreen}
                            alt="LinesGreen"
                            width="15"
                            height="15"
                        />
                    </button>
                    {showProfileCard && <LoggedOutSection />}
                </div>
            )}
        </div>
    );
}
