'use client';
import { useGetTheme } from '@/theme';
import { CssBaseline, ThemeProvider } from '@mui/material';

export function Theme({ children }: { children: React.ReactNode }) {
    const theme = useGetTheme();
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <>
                <>{children}</>
            </>
        </ThemeProvider>
    );
}
