import { createSlice, PayloadAction } from '@reduxjs/toolkit';
const initialState: {
    redirectedFromAddPaymentButton: boolean;
} = {
    redirectedFromAddPaymentButton: false,
};

export const bookingForPaymentLogsReducer = createSlice({
    name: 'bookingForPaymentLogs',
    initialState,
    reducers: {
        setRedirectedFromAddPaymentButton: (
            state,
            action: PayloadAction<boolean>
        ) => {
            state.redirectedFromAddPaymentButton = action.payload;
        },
    },
});

export const { setRedirectedFromAddPaymentButton } =
    bookingForPaymentLogsReducer.actions;

export default bookingForPaymentLogsReducer.reducer;
