'use client';
import { useEffect, useState } from 'react';

import classnames from 'classnames';

import {
    buyCarIconDisabled,
    buyCarIconEnabled,
    homeIconDisabled,
    homeIconEnabled,
    ProfileIconGreen,
    ProfileIconGrey,
    sellCarIconDisabled,
    sellCarIconEnabled,
} from '../../../../public/Images';
import styles from './styles.module.scss';
import Link from '@/components/WrappedLink';
import { useTranslation, useWindowDimensions } from '@/hook';
import { usePathname } from 'next/navigation';
import Image from 'next/image';
import Typography from '@/components/shared/typography/typography.component';
import { shouldHideFooter } from '@/utils/footer.utils';

export default function MobileNavigation() {
    const { t, i18n } = useTranslation();
    const lang = i18n.language;
    const TABS = [
        {
            id: 1,
            name: 'home',
            activeIcon: homeIconEnabled,
            disabledIcon: homeIconDisabled,
            path: `/${lang}/`,
            alt: 'Home Icon',
        },
        {
            id: 2,
            name: 'Buy Car',
            activeIcon: buyCarIconEnabled,
            disabledIcon: buyCarIconDisabled,
            path: `/${lang}/buy-used-cars`,
            alt: 'Buy Car Icon',
        },
        {
            id: 3,
            name: 'Sell Car',
            activeIcon: sellCarIconEnabled,
            disabledIcon: sellCarIconDisabled,
            path: `/${lang}/sell-car`,
            alt: 'Sell Car Icon',
        },
        {
            id: 4,
            name: 'myAccount',
            activeIcon: ProfileIconGreen,
            disabledIcon: ProfileIconGrey,
            path: `/${lang}/account`,
            alt: 'Account Icon',
        },
    ];
    const path = usePathname();
    const { IS_DESKTOP: desktopView } = useWindowDimensions();
    const arrayPath = path.split('/');
    const pathLength = arrayPath.length;
    const current = pathLength === 2 ? '' : arrayPath[pathLength - 1];
    const [hideFooter, setHideFooter] = useState(
        shouldHideFooter(desktopView, path)
    );

    useEffect(() => {
        const footerFlag = shouldHideFooter(desktopView, path);
        if (hideFooter !== footerFlag) {
            setHideFooter(footerFlag);
        }
    }, [path]);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
    }, [current]);

    return (
        <div
            className={`${styles.container} ${
                hideFooter ? 'slndr-hidden' : ''
            }`}
        >
            {TABS.map(tab => {
                return (
                    <Link
                        key={tab.id}
                        className={styles.IconContainer}
                        onClick={() =>
                            current === tab.path &&
                            window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: 'auto',
                            })
                        }
                        href={tab.path}
                    >
                        <Image
                            src={
                                `/${lang}/${current}` === tab.path
                                    ? tab.activeIcon.src
                                    : tab.disabledIcon.src
                            }
                            width={tab.activeIcon.width}
                            height={tab.activeIcon.height}
                            alt={tab.alt}
                            className={styles.icon}
                        />
                        <Typography
                            className={classnames(styles.tabName, {
                                [styles.active]:
                                    `/${lang}/${current}` === tab.path,
                            })}
                        >
                            {t(tab.name as unknown as TemplateStringsArray)}
                        </Typography>
                    </Link>
                );
            })}
        </div>
    );
}
