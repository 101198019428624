import React, { useState } from 'react';

interface IgolobalConfig {
    hideFooter: boolean;
    setConfig: (arg: any) => void;
}
export const globalConfigContext = React.createContext<IgolobalConfig>({
    hideFooter: false,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setConfig: (config: any) => {
        //do nothing.
    },
});

export const GlobalConfigContextProvider = props => {
    const setConfig = (config: Partial<IgolobalConfig>) => {
        setState({ ...state, ...config });
    };

    const initState = {
        hideFooter: false,
        setConfig: setConfig,
    };

    const [state, setState] = useState(initState);

    return (
        <globalConfigContext.Provider value={state}>
            {props.children}
        </globalConfigContext.Provider>
    );
};
