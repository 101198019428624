'use client';

import Link from '@/components/WrappedLink';
import Image from 'next/image';
import colors from '@/theme/colors.module.scss';
import LinkButton, {
    EButtonVariant,
} from '../../Button/link-button/link-button.component';
import {
    AboutUsIcon,
    BuyCarIcon,
    FAQsIcon,
    FinanceLogo,
    SellCarIcon,
    SylndrLogo,
} from '../../../../../public/NavBar';
import { ProfileIconGrey } from '../../../../../public/Images';

import { usePathname } from 'next/navigation';
import styles from './styles.module.scss';

import Typography from '@/components/shared/typography/typography.component';
import ChangeLanguage from '../change-langauge-client.component';
import { useTranslation } from '@/hook';
import useAuth from '@/hook/use-auth.hook';
import { AVAILABLE_LANGUAGES } from '@/constants';
import { useAnalytics } from '@/integrations';
import { AuthStepsEnum } from '@/types';
import { useEffect, useState } from 'react';
interface Props {
    lang: AVAILABLE_LANGUAGES;
}
interface ILink {
    id: number;
    label: string;
    path: string;
    pathName: string;
    image: any;
    newItem?: boolean;
}

export default function MobileNav({ lang }: Props) {
    const { t } = useTranslation();
    const pathname = usePathname();
    const { trackEvent } = useAnalytics();
    const {
        updateCurrentAuthStep,
        userId,
        userInitials,
        name,
        phone,
        signInOrUpClicked,
        isLoggedIn,
    } = useAuth();
    const LINKS: ILink[] = [
        {
            id: 0,
            label: 'Browse All Cars',
            path: `/${lang}/buy-used-cars`,
            pathName: 'buy-used-cars',
            image: BuyCarIcon,
        },
        {
            id: 1,
            label: 'Sell Your Car',
            path: `/${lang}/sell-car`,
            pathName: 'sell-car',
            image: SellCarIcon,
        },
        {
            id: 3,
            label: 'Why Sylndr',
            path: `/${lang}/why-sylndr`,
            pathName: 'why-sylndr',
            image: SylndrLogo,
        },
        {
            id: 4,
            label: 'FAQs',
            path: `/${lang}/how-it-works`,
            pathName: 'how-it-works',
            image: FAQsIcon,
        },
        {
            id: 5,
            label: 'Finance',
            path: `/${lang}/car-finance`,
            pathName: 'car-finance',
            image: FinanceLogo,
        },
        {
            id: 6,
            label: 'About us',
            path: `/${lang}/about-us`,
            pathName: 'about-us',
            image: AboutUsIcon,
        },
    ];
    const [linksState, setLinksState] = useState(LINKS);
    useEffect(() => {
        if (userId?.length) {
            const myAccountAlreadyExists = linksState.some(
                obj => obj.label === 'myAccount'
            );
            if (!myAccountAlreadyExists) {
                const myAccountItem: ILink = {
                    id: Date.now(),
                    label: 'myAccount',
                    path: `/${lang}/account`,
                    pathName: 'account',
                    image: ProfileIconGrey,
                    newItem: true,
                };
                const newArray = [
                    ...LINKS.slice(0, 2),
                    myAccountItem,
                    ...LINKS.slice(2),
                ];
                setLinksState(newArray);
            }
        } else {
            setLinksState(LINKS);
        }
    }, [userId]);
    const returnCurrentPage = () => {
        const arr = pathname.split('/');
        const page = arr[arr.length - 1];
        return page;
    };
    const fireOnClickeBehavior = (label: string) => {
        if (label === 'Finance') {
            return trackEvent('Screen: Financing Page', {
                sourceScreen: 'Header',
            });
        }
        if (label === 'myAccount') {
            return updateCurrentAuthStep(AuthStepsEnum.profile);
        }
    };

    return (
        <>
            <div className={styles.tagContainer}>
                {isLoggedIn ? (
                    <div className={styles.contentWrapper}>
                        <div className={styles.iconContainer}>
                            <Typography variant="subtitle1Medium">
                                {userInitials}
                            </Typography>
                        </div>
                        <div className={styles.userDataContainer}>
                            <Typography variant="body2SemiBold" component="div">
                                {name}
                            </Typography>
                            <Typography variant="caption" component="div">
                                {phone}
                            </Typography>
                        </div>
                    </div>
                ) : (
                    <div
                        className={styles.contentWrapper}
                        onClick={() => signInOrUpClicked()}
                    >
                        <div className={styles.iconContainer}>
                            <Image
                                width={32}
                                height={32}
                                src={ProfileIconGrey}
                                alt={'ProfileIconGrey'}
                            />
                        </div>
                        <div>
                            <Typography
                                variant="body2SemiBold"
                                className={styles.signInLabel}
                            >
                                {t('signIn')}
                            </Typography>
                            <Typography variant="caption">
                                {t('dontHaveAnAccount')}
                            </Typography>
                            <Typography variant="caption" component="div">
                                <span className={styles.signUpLabel}>
                                    {t('signUp')}
                                </span>
                            </Typography>
                        </div>
                    </div>
                )}
            </div>
            <ul className={styles.linksContainer}>
                {linksState.map(link => {
                    return (
                        <li className={styles.navItem} key={link.id}>
                            <div className={styles.navItemWrapper}>
                                <Image
                                    width={160}
                                    height={26}
                                    src={link.image}
                                    alt={link.label}
                                    className={styles.icon}
                                />
                                <Link
                                    style={{
                                        color:
                                            link.pathName ===
                                            returnCurrentPage()
                                                ? colors.primary600
                                                : colors.gray900,
                                    }}
                                    href={link.path}
                                    // state={{
                                    //   sourcePage:
                                    //     link.path === 'buy-used-cars'
                                    //       ? 'Browse_Car_Sidebar'
                                    //       : undefined,
                                    // }}
                                    onClick={() =>
                                        fireOnClickeBehavior(link.label)
                                    }
                                >
                                    <Typography variant={'body1Medium'}>
                                        {t(
                                            link.label as unknown as TemplateStringsArray
                                        )}{' '}
                                    </Typography>
                                </Link>
                            </div>
                            {link.newItem && (
                                <div className={styles.newItemWrapper}>
                                    <Typography variant="captionMedium">
                                        {t('newLabel')}
                                    </Typography>
                                </div>
                            )}
                        </li>
                    );
                })}
                <li
                    className={`${styles.navItem} ${styles.langItem} ${styles.langSelectorMobile}`}
                >
                    <ChangeLanguage lang={lang} />
                </li>
            </ul>
            <hr style={{ border: 'none', background: 'lighgray' }} />
            <div className={styles.buttonsContainer}>
                <LinkButton
                    style={{ height: '48px' }}
                    variant={EButtonVariant.PRIMARY}
                    href={`/${lang}/buy-used-cars`}

                    //   state={{ sourcePage: 'Buy_Header' }}
                >
                    {t('Buy Car')}
                </LinkButton>

                <LinkButton
                    style={{ height: '48px' }}
                    href={`/${lang}/sell-car`}
                    variant={EButtonVariant.INVERTED}
                >
                    {t('Sell Car')}
                </LinkButton>
            </div>
        </>
    );
}
