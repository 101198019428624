import { createApi } from '@reduxjs/toolkit/query/react';
import { ICustomerBooking, PaginatedData } from '@/types';
import { NEST_RETAIL_BACKEND } from '@/constants';
import { IGetUserBookingsResponse } from '@/types';
import { baseQueryWrapper } from '@/libs/baseQueryWrapper.lib';

export const userBookingsApiSlice = createApi({
    reducerPath: 'userBookings',
    baseQuery: baseQueryWrapper({ baseUrl: `${NEST_RETAIL_BACKEND}` }),
    tagTypes: ['UserBookings'],
    keepUnusedDataFor: 0,
    endpoints: builder => ({
        getMyBookings: builder.query<PaginatedData<ICustomerBooking>, any>({
            query: () => ({
                url: `/v1.0/booking?page=${1}&limit=${100}&sortOrder=DESC&sortBy=createdAt`,
                method: 'GET',
            }),
            transformResponse: (
                response: IGetUserBookingsResponse
            ): PaginatedData<ICustomerBooking> => {
                const data = response.payload;

                return data;
            },
        }),
    }),
});

export const { useGetMyBookingsQuery } = userBookingsApiSlice;
