'use client';
import styles from '../styles.module.scss';
import Typography from '@/components/shared/typography/typography.component';
import {
    BookedCarsIconGrey,
    ProfileIconGrey,
    SellCarIconGrey,
    LogoutGrey,
} from '../../../../../public/Images';
import Image from 'next/image';
import useAuth from '@/hook/use-auth.hook';
import { useRouter } from 'next/navigation';
import { useTranslation } from '@/hook';
import { AuthStepsEnum } from '@/types';
import colors from '@/theme/colors.module.scss';

export default function LoggedOutSection() {
    const { i18n, t, IS_RTL } = useTranslation();
    const lang = i18n.language;
    const { setShowProfileCardWrapper, updateCurrentAuthStep } = useAuth();
    const router = useRouter();

    const loginClicked = () => {
        updateCurrentAuthStep(AuthStepsEnum.phone);
        setShowProfileCardWrapper(false);
        router.push(`/${lang}/register`);
    };
    return (
        <div
            className={`${styles.sectionContainer}
            ${
                IS_RTL
                    ? styles.sectionContainerWrapperRTL
                    : styles.sectionContainerWrapperLTR
            }`}
        >
            <div
                className={
                    IS_RTL ? styles.dataContainerRTL : styles.dataContainerLTR
                }
            >
                <div
                    className={`${styles.tagContainer} ${styles.clickable}`}
                    onClick={() => loginClicked()}
                >
                    <div className={styles.iconContainer}>
                        <Image
                            src={ProfileIconGrey}
                            alt="ProfileIconGrey"
                            width="27"
                            height="27"
                        />
                    </div>
                    <div>
                        <Typography
                            variant="body2SemiBold"
                            style={{ marginBottom: '4px' }}
                        >
                            {t('signIn')}
                        </Typography>
                        <Typography variant="caption" component="div">
                            {t('dontHaveAnAccount')}
                        </Typography>
                        <Typography variant="caption" color={colors.primary600}>
                            {t('signUp')}
                        </Typography>
                    </div>
                </div>

                <div className={styles.nonClickable}>
                    <div className={styles.personalInfoContainer}>
                        <Image
                            src={ProfileIconGrey}
                            alt="ProfileIconGrey"
                            width="16"
                            height="16"
                        />
                        <Typography variant="body2" color={colors.gray400}>
                            {t('personalInformation')}
                        </Typography>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.optionsWrapper}>
                        <div className={styles.optionContainer}>
                            <Image
                                src={BookedCarsIconGrey}
                                alt="BookedCarsIconGrey"
                                width="16"
                                height="16"
                            />
                            <Typography variant="body2" color={colors.gray400}>
                                {t('buyCarRequests')}
                            </Typography>
                        </div>
                        <div className={styles.optionContainer}>
                            <Image
                                src={SellCarIconGrey}
                                alt="SellCarIconGrey"
                                width="16"
                                height="16"
                            />
                            <Typography variant="body2" color={colors.gray400}>
                                {t('sellCarRequests')}
                            </Typography>
                        </div>
                    </div>
                    <div className={styles.divider}></div>
                    <div className={styles.optionContainer}>
                        <Image
                            src={LogoutGrey}
                            alt="LogoutGrey"
                            width="16"
                            height="16"
                        />
                        <Typography variant="body2" color={colors.gray400}>
                            {t('logout')}
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    );
}
