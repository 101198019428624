'use client';
import styles from '../styles.module.scss';
import Typography from '@/components/shared/typography/typography.component';
import {
    LogoutBlack,
    BookedCarsIconBlack,
    SellCarIconBlack,
    ProfileIconBlack,
} from '../../../../../public/Images';
import Image from 'next/image';
import useAuth from '@/hook/use-auth.hook';
import { useRouter } from 'next/navigation';
import { useTranslation } from '@/hook';
import { AuthStepsEnum } from '@/types';
import colors from '@/theme/colors.module.scss';

export default function LoggedInSection() {
    const { i18n, t, IS_RTL } = useTranslation();

    const lang = i18n.language;
    const {
        name,
        phone,
        userInitials,
        clearTokenAndLogout,
        setShowProfileCardWrapper,
        updateCurrentAuthStep,
    } = useAuth();
    const router = useRouter();
    const logout = () => {
        setShowProfileCardWrapper(false);
        clearTokenAndLogout();
        router.push(`/${lang}`);
    };
    const personalInfoClicked = () => {
        updateCurrentAuthStep(AuthStepsEnum.profile);
        setShowProfileCardWrapper(false);
        router.push(`/${lang}/account/profile`);
    };
    const sellCarRequestsClicked = () => {
        // setShowProfileCardWrapper(false);
        // router.push(`/${i18n.language}/account/myappointments`);
    };

    const buyCarRequestsClicked = () => {
        setShowProfileCardWrapper(false);
        router.push(`/${i18n.language}/account/mybookings`);
    };

    return (
        <div
            className={`${styles.sectionContainer}
            ${
                IS_RTL
                    ? styles.sectionContainerWrapperRTL
                    : styles.sectionContainerWrapperLTR
            }`}
        >
            <div
                className={
                    IS_RTL ? styles.dataContainerRTL : styles.dataContainerLTR
                }
            >
                <div className={styles.tagContainer}>
                    <div className={styles.iconContainer}>
                        <Typography
                            variant="subtitle1Medium"
                            color={colors.gray700}
                        >
                            {userInitials}
                        </Typography>
                    </div>

                    <div>
                        <Typography
                            variant="body2SemiBold"
                            style={{ marginBottom: '4px' }}
                        >
                            {name}
                        </Typography>
                        <Typography variant="caption" component="div">
                            {phone}
                        </Typography>
                    </div>
                </div>
                <div
                    className={`${styles.personalInfoContainer} ${styles.clickable}`}
                    onClick={() => {
                        personalInfoClicked();
                    }}
                >
                    <Image
                        src={ProfileIconBlack}
                        alt="ProfileIconBlack"
                        width="16"
                        height="16"
                    />
                    <Typography variant="body2">
                        {t('personalInformation')}
                    </Typography>
                </div>
                <div className={styles.divider} />
                <div className={styles.optionsWrapper}>
                    <div
                        className={`${styles.optionContainer} `}
                        onClick={buyCarRequestsClicked}
                    >
                        <Image
                            src={BookedCarsIconBlack}
                            alt="BookedCarsIconBlack"
                            width="16"
                            height="16"
                        />
                        <Typography
                            variant="body2"
                            // color={colors.gray400}
                        >
                            {t('buyCarRequests')}
                        </Typography>
                    </div>
                    <div
                        className={`${styles.optionContainer} ${styles.temporarlyDisabled} `}
                        onClick={sellCarRequestsClicked}
                    >
                        <Image
                            src={SellCarIconBlack}
                            alt="SellCarIconBlack"
                            width="16"
                            height="16"
                        />
                        <Typography variant="body2">
                            {t('sellCarRequests')}
                        </Typography>
                    </div>
                </div>
                <div className={styles.divider}></div>
                <div
                    className={`${styles.optionContainer} ${styles.clickable}`}
                    onClick={() => logout()}
                >
                    <Image
                        src={LogoutBlack}
                        alt="LogoutBlack"
                        width="16"
                        height="16"
                    />
                    <Typography
                        variant="body2"
                        // color={colors.gray400}
                    >
                        {t('logout')}
                    </Typography>
                </div>
            </div>
        </div>
    );
}
