'use client';

import { ReduxProvider } from './redux.wrapper';
import { Theme } from './theme.wrapper';
import { GlobalConfigContextProvider } from '@/wrappers/globalConfig.weapper';

const Providers = ({ children }: { children: React.ReactNode }) => {
    return (
        <Theme>
            <ReduxProvider>
                <GlobalConfigContextProvider>
                    <>{children}</>
                </GlobalConfigContextProvider>
            </ReduxProvider>
        </Theme>
    );
};
export default Providers;
