import { createApi } from '@reduxjs/toolkit/query/react';
import {
    IGenericResponse,
    INewConactCreateBody,
    IUserSignInBody,
    IUserSignInReponse,
    IUserUpdateInfoBody,
    IValidateAndNewConactCreateBody,
    IValidateAndLoginBody,
    IValidateAndLoginResponse,
} from '@/types';
import { SYLNDR_USER_SERVICE_BASE_URL } from '@/constants';
import { baseQueryWrapper } from '@/libs/baseQueryWrapper.lib';

export const userApiSlice = createApi({
    reducerPath: 'user',
    baseQuery: baseQueryWrapper({ baseUrl: SYLNDR_USER_SERVICE_BASE_URL }),
    tagTypes: [],
    endpoints: builder => ({
        addNewContact: builder.mutation<IGenericResponse, INewConactCreateBody>(
            {
                query: ({ name, signedPhone, userId }) => ({
                    url: '/v1.0/contact',
                    method: 'POST',
                    body: { name, signedPhone, userId },
                }),
            }
        ),
        userSignIn: builder.mutation<IUserSignInReponse, IUserSignInBody>({
            query: ({ phone, otp }) => ({
                url: '/v1.0/user-auth/sign-in',
                method: 'POST',
                body: { phone, otp },
            }),
        }),
        userUpdateToken: builder.mutation<IUserSignInReponse, void>({
            query: () => ({
                url: '/v1.0/user-auth/refresh-token',
                method: 'GET',
            }),
        }),
        updateUserInfo: builder.mutation<IGenericResponse, IUserUpdateInfoBody>(
            {
                query: ({ id, ...rest }) => ({
                    url: `/v1.0/user/${id}`,
                    method: 'PATCH',
                    body: rest,
                }),
            }
        ),
        validateAndAddNewContact: builder.mutation<
            IGenericResponse,
            IValidateAndNewConactCreateBody
        >({
            query: ({ name, phone, userId, otp }) => ({
                url: '/v1.0/contact/validate-and-create',
                method: 'POST',
                body: { name, phone, userId, otp },
            }),
        }),
        validateAndLogin: builder.mutation<
            IValidateAndLoginResponse,
            IValidateAndLoginBody
        >({
            query: ({ otp, name, phone, email }) => {
                const body: IValidateAndLoginBody = {
                    otp,
                    name,
                    phone,
                };
                if (email) body.email = email;
                return {
                    url: '/v1.0/user-auth/validate-and-login',
                    method: 'POST',
                    body,
                };
            },
        }),
    }),
});

export const {
    useAddNewContactMutation,
    useUserSignInMutation,
    useUserUpdateTokenMutation,
    useUpdateUserInfoMutation,
    useValidateAndAddNewContactMutation,
    useValidateAndLoginMutation,
} = userApiSlice;
