import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CarListingData, ICarListingReducer } from '@/types';

const initialState: ICarListingReducer = {
    listings: [],
    displayedListings: [],
    filteredListings: [],
    carsFoundAfterFilter: -1,
};

export const carListingReducer = createSlice({
    name: 'carListing',
    initialState,
    reducers: {
        setIntialState: (state, action: PayloadAction<CarListingData[]>) => {
            state.listings = action.payload;
            state.displayedListings = action.payload;
            state.filteredListings = action.payload;
        },
        setListings: (state, action: PayloadAction<CarListingData[]>) => {
            state.listings = action.payload;
        },
        setDisplayedListings: (
            state,
            action: PayloadAction<CarListingData[]>
        ) => {
            state.displayedListings = action.payload;
        },
        setFilteredListings: (
            state,
            action: PayloadAction<CarListingData[]>
        ) => {
            state.filteredListings = action.payload;
        },
        setCarsFoundAfterFilter: (state, action: PayloadAction<number>) => {
            state.carsFoundAfterFilter = action.payload;
        },
        clearData: state => {
            state.listings = [];
            state.displayedListings = [];
            state.filteredListings = [];
        },
    },
});

export const {
    setIntialState,
    setListings,
    setFilteredListings,
    setDisplayedListings,
    setCarsFoundAfterFilter,
    clearData,
} = carListingReducer.actions;

export default carListingReducer.reducer;
