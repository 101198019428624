import { configureStore } from '@reduxjs/toolkit';
import {
    carListingReducer,
    checkoutReducer,
    financingReducer,
    financingResultReducer,
    galleryReducer,
    sellCarFormReducer,
    sellFomData,
    sellFormStepper,
    sortingReduceer,
    authReducer,
} from './reducers';
import { apiSlice, interceptorApiSlice } from '@/hook/retail-api-slice';
import { acquistionApiSlice } from '@/hook/acquisition-api-slice';
import { geocoderApiSlice } from '@/hook/geocoder-api-slice';
import { testimonialApiSlice } from '@/hook/testimonial-api-slice';
import createAlertReducer from './reducers/create-alert.reducer';
import { otpApiSlice } from '@/hook/slices/otp-api-slice';
import { userApiSlice } from '@/hook/slices/user-api-slice';
import { faqsApiSlice } from '@/hook/faqs-api-slice';
import { userBookingsApiSlice } from '@/hook/slices/user-bookings-slice';
import { acquisitionInterceptorApiSlice } from '@/hook/acquisition-api-slice-new';
import { userBookingsDetailsApiSlice } from '@/hook/slices/booking-details-slice';
import { bookingForPaymentLogsReducer } from '@/redux/reducers/bookings-for-payment-logs-reducer';

const store = configureStore({
    reducer: {
        carListings: carListingReducer,
        sort: sortingReduceer,
        gallery: galleryReducer,
        checkout: checkoutReducer,
        createAlert: createAlertReducer,
        financing: financingReducer,
        financingResult: financingResultReducer,
        sellCarForm: sellCarFormReducer,
        sellFomData: sellFomData,
        sellFormStepper: sellFormStepper,
        auth: authReducer,
        bookingForPaymentLogs: bookingForPaymentLogsReducer.reducer,
        [apiSlice.reducerPath]: apiSlice.reducer,
        [acquistionApiSlice.reducerPath]: acquistionApiSlice.reducer,
        [faqsApiSlice.reducerPath]: faqsApiSlice.reducer,
        [testimonialApiSlice.reducerPath]: testimonialApiSlice.reducer,
        [geocoderApiSlice.reducerPath]: geocoderApiSlice.reducer,
        [otpApiSlice.reducerPath]: otpApiSlice.reducer,
        [userApiSlice.reducerPath]: userApiSlice.reducer,
        [interceptorApiSlice.reducerPath]: interceptorApiSlice.reducer,
        [userBookingsApiSlice.reducerPath]: userBookingsApiSlice.reducer,
        [userBookingsDetailsApiSlice.reducerPath]:
            userBookingsDetailsApiSlice.reducer,
        [acquisitionInterceptorApiSlice.reducerPath]:
            acquisitionInterceptorApiSlice.reducer,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({ serializableCheck: false }).concat(
            apiSlice.middleware,
            acquistionApiSlice.middleware,
            faqsApiSlice.middleware,
            testimonialApiSlice.middleware,
            geocoderApiSlice.middleware,
            otpApiSlice.middleware,
            userApiSlice.middleware,
            interceptorApiSlice.middleware,
            userBookingsApiSlice.middleware,
            userBookingsApiSlice.middleware,
            userBookingsDetailsApiSlice.middleware,
            acquisitionInterceptorApiSlice.middleware
        ),

    devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
